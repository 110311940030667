import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions"
    }}><strong parentName="h1">{`Frequently Asked Questions`}</strong></h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`What is Nano API?`}</strong>
        {`  NanoAPI is a novel approach to cloud hosting for Web-based Application Programming Interfaces (APIs). Traditionally, company web software is deployed to always-on cloud servers through products like AWS EC2 or Digital Ocean Kubernetes.`}
        {`  With NanoAPI, these same codebases are split apart at deploy time: providing both speed of software development, and speed of operation and cost efficiency of the software at run-time. This approach provides energy efficiency and cost efficiency, allowing companies to scale their software from 10 users to 10,000 users, or 10,000 to 10 million, without their services falling over or breaking the bank. Traditional alternatives to NanoAPI would require either paying high cloud costs to scale inefficiently, or else refactor the large codebases into smaller ones, costing thousands of hours of lost developer time.
`}</li>
      <li parentName="ul"><strong parentName="li">{`What is a Nanoservice?`}</strong>
        {`  A Nanoservice is a backend service that is responsible for a single API endpoint. This is in contrast to a monolithic webserver, which is responsible for all endpoints. This API endpoint can be a single function or a group of functions that are related to a single task, and will turn off or on as needed to conserve energy and costs.
`}</li>
      <li parentName="ul"><strong parentName="li">{`What are our cloud service features?`}</strong>
        {`  We provide a platform for deploying and managing your services in the cloud. We also provide a set of tools to help you manage your services, such as automatic scaling, monitoring, and logging.`}
        {`  We do this to better reduce the energy consumption of your services, and to help you save money on your cloud bills.`}
        {`  For now, only API hosting is supported. We are working on adding more features in the future, such as database hosting, storage, and more.`}
        {`  For now, we have several partners we work with to serve your needs which we can direct you to. We are always looking for more partners to work with, so if you have a service you would like to see integrated with NanoAPI, please let us know!
`}</li>
      <li parentName="ul"><strong parentName="li">{`How does NanoAPI work?`}</strong>
        {`  NanoAPI consists of several technologies working in tandem to meet user hosting needs:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Transpilation:`}</strong>{` This approach is what sets us apart from other cloud hosting platforms. By connecting their codebases, we take their code and transform it at build-time into a swarm of Nanoservices, through an algorithm developed by Joel Milligan. This improves their efficiency and speed. Soon, NanoAPI will offer support for this functionality to all programming languages, and this will become a new standard for software development akin to monorepos (monolith repositories).`}</li>
          <li parentName="ul"><strong parentName="li">{`API Hosting:`}</strong>{` Companies connect their codebases to us, and we build and deploy their software to our hosting servers. This way, we offer SSL/TLS encryption for user domains, along with optional rate-limiting.`}</li>
          <li parentName="ul"><strong parentName="li">{`On-Demand Computing:`}</strong>{` Using Nanoservices (serverless APIs) allows for us to start user code when a user requests it, and shut it back off when no users are on the system. This saves energy usage and costs for the end user.`}</li>
          <li parentName="ul"><strong parentName="li">{`Scaling-as-a-Service:`}</strong>{` NanoAPI can then freely scale the nanoservices to meet user demand, from one request per second to hundreds of thousands. This provides peace of mind to SMEs and Enterprises alike.`}</li>
          <li parentName="ul"><strong parentName="li">{`CI/CD and Integrations:`}</strong>{` NanoAPI integrates with code hosting platforms such as Github and Gitlab to provide simple CI/CD using pipelines that are defined and run close to the code; turning any developer into a DevOps professional.`}</li>
          <li parentName="ul"><strong parentName="li">{`Monitoring:`}</strong>{` NanoAPI aggregates logs and errors, and displays them to the developers in a consistent way.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Who is NanoAPI For?`}</strong>
        {`  It’s easy to say we can support anyone using a website with an API. While we can, we have narrowed the users for who our value proposition is strongest down to the following:`}
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Domains:`}</strong>{` SaaS and tech-based SMEs within Europe who need to scale existing software while meeting GDPR and CSRD requirements.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Financial Size:`}</strong>{` We target companies between 1-50 million ARR. These companies will be able to use us without requiring too many security certifications, which are in the pipeline for us.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Team Size:`}</strong>{` Our customers are companies with developer teams between 3 and 50 people, who are looking to gain peace of mind about scaling their infrastructure and creating good internal software practices and workflows.`}</p>
            <p parentName="li">{`As we grow and Nanoservices gain traction within the developer community, the types of customers we serve will expand outwards into other domains which require technical teams. We will offer support for programming languages in the following order:`}</p>
          </li>
        </ul>
        <ol parentName="li">
          <li parentName="ol">{`NodeJS`}</li>
          <li parentName="ol">{`Python`}</li>
          <li parentName="ol">{`Java`}</li>
          <li parentName="ol">{`Golang`}</li>
          <li parentName="ol">{`Others`}</li>
        </ol>
      </li>
      <li parentName="ul"><strong parentName="li">{`How will developers interact with NanoAPI`}</strong>
        {`  NanoAPI’s products are designed to supercharge developer workflows. By simply connecting a codebase to NanoAPI, a developer gets access to a range of powerful tools that allow them to focus on their code and be confident their software will deploy in an efficient way every single time. The feature set we provide works well with their existing cloud providers, and we will even enable deployment to these providers to reduce the number of developers required for a team, but to unlock the true power of NanoAPI, companies will have to deploy to our hosting solution.`}
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Traditional Approach`}</p>
            <p parentName="li">{`  For traditional hosting, the workflow of a developer looks something like the following:`}</p>
            <ol parentName="li">
              <li parentName="ol">
                <p parentName="li">{`Write API codebase.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Setup an AWS account.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Setup an AWS EC2 (or other server technology).`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Configure subnets, and other networking concerns.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Deploy a single instance of their software to the single server.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Figure out how to use a process manager to keep the software alive.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Figure out how to setup a good CI/CD workflow to automatically deploy new versions.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Configure TLS/SSL certificates for their server and domain name, which must be refreshed often.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Figure out how to monitor the health of their service and aggregate logs.`}</p>
                <p parentName="li">{`Only one of the above steps actually involves writing code and delivering features. While this solution will work to serve a few users, once the application or business begins to see more than 20 or 50 users, the software will either break, or cost a large sum of money to scale.`}</p>
              </li>
            </ol>
          </li>
          <li parentName="ul">
            <p parentName="li">{`NanoAPI Approach`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`With NanoAPI, the workflow changes to the following:

1. Sign Up to NanoAPI.
2. Search for a Repository.
3. Build configuration.
4. Create an API Token.
5. Set Up Your CI/CD Pipeline.
6. Push a New Commit

And that’s it! From here, the developer(s) can focus on delivering features and not worry about the hosting, scaling, certificates, or spiraling costs due to cloud hosting and scale.
`}</code></pre>
            <p parentName="li">{`In case of bugs or breaking changes in the live environments, a developer can simply check the logs and pull down the split version of their code (the Nanoservice) for testing, or even revert to previous deployment versions.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Can I use NanoAPI on my existing services?`}</strong>
        {`  Yes, NanoAPI can deploy your existing services. We support a wide range of languages and frameworks, and we are always adding more. If you have a service that you would like to deploy with NanoAPI, please let get in contact!`}
        {`  Also, NanoAPI can deploy to your cloud provider. We support a wide range of cloud providers. However, we recommend using our cloud hosting for the best experience and to get the most use out of our benefits.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      